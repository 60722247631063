<div class="content-wrapper">
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card mrr-card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-3">
              <div class="card mrr-info--cards card--brands">
                <a routerLink="/admin/activepardna">
                  <div class="card-body pb-0">
                    <i class="fa fa-star"></i>
                    <div class="text-value">{{ statsValue?.pardnas || 0 }}</div>
                    <div class="card--name">Active Pardnas</div>
                  </div>
                  <div class="chart-wrapper mt-3 mx-3"></div>
                </a>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card mrr-info--cards card--rewards">
                <a routerLink="/admin/users">
                  <div class="card-body pb-0">
                    <i class="fa fa-user"></i>
                    <div class="text-value">{{ statsValue?.users || 0 }}</div>
                    <div class="card--name">Active Users</div>
                  </div>
                  <div class="chart-wrapper mt-3"></div>
                </a>
              </div>
            </div>

            <div class="col-sm-3">
              <div class="card mrr-info--cards card--payments">
                <a routerLink="/admin/payments">
                  <div class="card-body pb-0">
                    <i class="fa fa-money"></i>
                    <div class="text-value">
                      {{ payments || 0 }}
                    </div>
                    <div class="card--name">Payments</div>
                  </div>
                  <div class="chart-wrapper mt-3"></div>
                </a>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card mrr-info--cards" style="border:1px solid #007bff">
                <a routerLink="/admin/affiliate">  
                  <div class="card-body pb-0">
                    <i class="fa fa-money"></i>
                    <div class="text-value">
                      {{ statsValue?.affilates || 0 }}
                    </div>
                    <div class="card--name">Affiliates</div>
                  </div>
                  <div class="chart-wrapper mt-3"></div>
                </a>
              </div>
            </div>
            <!--<div class="col-sm-3">
                            <div class="card mrr-info--cards card--offers">
                                <div class="card-body pb-0">
                                    <i class="fa fa-tag"></i>
                                    <div class="text-value">{{statsValue?.totalCardlinked}}</div>
                                    <div class="card--name">Cardlinked offers</div>
                                </div>
                                <div class="chart-wrapper mt-3 mx-3">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="card mrr-info--cards cards--users">
                                <div class="card-body pb-0">
                                    <i class="fa fa-user"></i>
                                    <div class="text-value">{{UserCount}}</div>
                                    <div class="card--name">Users</div>
                                </div>
                                <div class="chart-wrapper mt-3 mx-3">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="card mrr-info--cards card--landlords">
                                <div class="card-body pb-0">
                                    <i class="fa fa-user"></i>
                                    <div class="text-value">{{statsValue?.totalLandlord}}</div>
                                    <div class="card--name">LandLords</div>
                                </div>
                                <div class="chart-wrapper mt-3 mx-3">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="card mrr-info--cards card--covid">
                                <div class="card-body pb-0">
                                    <i class="fa fa-user"></i>
                                    <div class="text-value">{{covidUsersCount}}</div>
                                    <div class="card--name">Covid-19</div>
                                </div>
                                <div class="chart-wrapper mt-3 mx-3">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="card mrr-info--cards card--transaction">
                                <div class="card-body pb-0">
                                    <i class="fa fa-exchange"></i>
                                    <div class="text-value">{{statsValue?.totalTransaction+statsValue?.totalAffiliateTransaction}}</div>
                                    <div class="card--name">Total Transactions</div>
                                </div>
                                <div class="chart-wrapper mt-3 mx-3">
                                </div>
                            </div>
                        </div> -->
            <!-- <div class="col-sm-3">
                            <div class="card mrr-info--cards card--affected">
                                <div class="card-body pb-0">
                                    <i class="fa fa-gbp"></i>
                                    <div class="text-value">{{addvalue(statsValue?.totalFidelAmount , statsValue?.totalAffiliateAmount)}}</div>
                                    <div class="card--name">Total Transaction Amount</div>
                                </div>
                                <div class="chart-wrapper mt-3 mx-3">
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
