<div class="container-scroller">
  <div class="container page-body-wrapper full-page-wrapper">
    <div class="main-panel">
      <div class="content-wrapper login--page">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-lg-4">
            <div class="auth-form-light">
              <div class="brand-logo text-center">
                <img src="assets/logo/imgpsh_fullsize_anim.png" alt="logo" />
              </div>
              <h4 class="text-center">Pardna App</h4>
              <form
                class="pt-3"
                [formGroup]="registerForm"
                (submit)="onSubmit()"
              >
                <div class="form-group">
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    name="email"
                    formControlName="email"
                  />
                  <div
                    *ngIf="formSubmitted && registerForm.controls.email.errors"
                    class="has-error"
                  >
                    <span *ngIf="registerForm.controls.email.errors.required"
                      >Email is required</span
                    >
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    autocomplete="off"
                    name="password"
                    formControlName="password"
                  />
                  <div
                    *ngIf="
                      formSubmitted && registerForm.controls.password.errors
                    "
                    class="has-error"
                  >
                    <span *ngIf="registerForm.controls.email.errors.required"
                      >Password is required</span
                    >
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    class="btn btn-block auth-form-btn btn-brand"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
