import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  userDetail;
  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    console.log(this.userDetail);
  }

}
