<div class="content-wrapper">
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card mrr-card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="card--title-row">
                <h4 class="card-title">Active Pardnas</h4>
                <span class="totalnmbr">Total {{ userList?.length }}</span>
                <div class="filter--user text-left mr-2">
                  <ng-select
                    [searchable]="false"
                    [clearable]="true"
                    bindLabel="statusName"
                    bindValue="statusName"
                    [items]="statusFilterArray"
                    (change)="onChangestatusFilter($event)"
                    placeholder="Show Pardnas"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card--title-button">
                <ng-select
                  style="width: 228px"
                  [items]="statusArray"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="Filter By Status"
                  (change)="changeStatus($event)"
                ></ng-select>
              </div>
            </div>
          </div>
          <div class="table-responsive pt-3">
            <table class="table user-data-table table-striped">
              <thead>
                <tr>
                  <th style="cursor: pointer" (click)="sortLisitng('name')">
                    Name
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'name',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'name'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('people')">
                    Members
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'people',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'people'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('amount')">
                    Amount
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'amount',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'amount'
                      }"
                    ></i>
                  </th>
                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('createdAt')"
                  >
                    Created<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'createdAt',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'createdAt'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('months')">
                    Months
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'months',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'months'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('payout')">
                    Payout
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'payout',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'payout'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('status')">
                    Status
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'status',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'status'
                      }"
                    ></i>
                  </th>
                  <th>Action</th>
                  <th>Test Pardna</th>
                </tr>
              </thead>
              <tbody>
                <ng-template
                  *ngIf="userList?.length != 0"
                  ngFor
                  let-pardna
                  [ngForOf]="
                    userList
                      | SortingFilter : sortvalue
                      | paginate : { itemsPerPage: 50, currentPage: p }
                  "
                  let-i="index"
                >
                  <tr>
                    <td>
                      <a
                        class="pardna--name"
                        routerLink="/admin/pardna/{{ pardna.id }}"
                        >{{ pardna.name }}</a
                      >
                    </td>
                    <td>{{ pardna.people }}</td>
                    <td>{{ pardna.amount ? "£" + pardna.amount : "" }}</td>
                    <td>{{ convertDate(pardna.createdAt) || "" }}</td>
                    <td>{{ pardna.months }}</td>
                    <td>{{ pardna.payout }}</td>
                    <td [ngClass]="pardna.status">{{ pardna.status }}</td>
                    <td>
                      <a
                        class="btn btn-outline-brand"
                        routerLink="/admin/pardna/{{ pardna.id }}"
                        >VIEW</a
                      >
                    </td>
                    <td>
                      <label class="switch">
                        <input
                          type="checkbox"
                          (click)="updatePardnaSandboxStatus($event, pardna.id)"
                          [checked]="pardna.sandbox"
                        />
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                </ng-template>
                <tr *ngIf="userList?.length == 0">
                  <td colspan="8" class="text-center">No documents found !</td>
                </tr>
              </tbody>
            </table>
            <div
              *ngIf="userList?.length > 0"
              class="pagination mt-4 float-right"
            >
              <pagination-controls
                (click)="clickPagination()"
                (pageChange)="p = $event"
                directionLinks="true"
                previousLabel="Previous"
                nextLabel="Next"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
