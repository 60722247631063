<div class="site-header">
    <div class="container">
        <nav class="navbar navbar-expand-lg site-navbar navbar-light justify-content-between">
            <a class="navbar-brand brand-logo">
                <img src="assets/logo/imgpsh_fullsize_anim.png" alt="logo" />
            </a>
            <div class="website--name">
                Pardna App
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="logoutButton">
                <a class="btn btn-outline-default btn-fw" (click)="logoutUser()">
                    <i class="fa fa-lock"></i> Logout
                </a>
            </div>
        </nav>
    </div>
</div>