<div class="content-wrapper">
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card mrr-card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="card--title-row">
                <h4 class="card-title">Users</h4>
                <span class="totalnmbr">Total {{ userlistArray?.length }}</span>
                <div class="filter--user text-left mr-2">
                  <ng-select
                    [searchable]="false"
                    [clearable]="true"
                    bindLabel="statusName"
                    bindValue="statusName"
                    [items]="statusFilterArray"
                    (change)="onChangestatusFilter($event)"
                    placeholder="Show Users"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card--title-button">
                <!-- <a class="btn btn-outline-brand" (click)="markAsTest()"> Mark As Test User </a> -->
              </div>
            </div>
          </div>
          <div class="table-responsive pt-3">
            <table class="table user-data-table table-striped">
              <thead>
                <tr>
                  <th>Photo</th>
                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('firstName')"
                  >
                    Full Name
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'firstName',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'firstName'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('email')">
                    Email
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'email',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'email'
                      }"
                    ></i>
                  </th>
                  <th
                    style="cursor: pointer"
                    (click)="sortByDate(dateOrderDesc)"
                  >
                    Date<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down': dateOrderDesc === false,
                        'fa-arrow-up': dateOrderDesc === true
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('bankName')">
                    Bank Name<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'bankName',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'bankName'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('account')">
                    Account<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'account',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'account'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('sortCode')">
                    Sort Code<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'sortCode',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'sortCode'
                      }"
                    ></i>
                  </th>
                  <th>Action</th>
                  <th>Test User</th>
                </tr>
              </thead>
              <tbody>
                <ng-template
                  *ngIf="userlistArray?.length != 0"
                  ngFor
                  let-userList
                  [ngForOf]="
                    userlistArray
                      | SortingFilter : sortvalue
                      | paginate : { itemsPerPage: 50, currentPage: p }
                  "
                  let-i="index"
                >
                  <tr>
                    <td>
                      <div class="userthumbnail">
                        <span class="user-thumb">
                          <img
                            *ngIf="userList?.photoURL"
                            [src]="userList.photoURL"
                          />
                          <img
                            *ngIf="!userList?.photoURL"
                            src="assets/logo/imgpsh_fullsize_anim.png"
                          />
                        </span>
                        <div class="cell ml-3"></div>
                      </div>
                    </td>
                    <td>
                      <a
                        class="user--name"
                        routerLink="/admin/user/{{ userList.id }}"
                        >{{ userList.firstName }} {{ userList.lastName }}</a
                      >
                    </td>
                    <td>{{ userList?.email }}</td>
                    <td>{{ changeDateFormat(userList.createdAt) || "" }}</td>
                    <td>{{ userList?.bank?.name || "" }}</td>
                    <td>{{ userList?.bank?.account || "" }}</td>
                    <td>{{ userList?.sortCode || "" }}</td>
                    <td>
                      <a
                        class="btn btn-outline-brand"
                        routerLink="/admin/user/{{ userList.id }}"
                        >VIEW</a
                      >
                    </td>
                    <td>
                      <label class="switch">
                        <input
                          type="checkbox"
                          (click)="updateUserSandboxStatus($event, userList.id)"
                          [checked]="userList.sandbox"
                        />
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                </ng-template>
                <tr *ngIf="userlistArray?.length == 0">
                  <td colspan="6" class="text-center">No documents found !</td>
                </tr>
              </tbody>
            </table>
            <div
              *ngIf="userlistArray?.length > 0"
              class="pagination mt-4 float-right"
            >
              <pagination-controls
                (click)="clickPagination()"
                (pageChange)="p = $event"
                directionLinks="true"
                previousLabel="Previous"
                nextLabel="Next"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
