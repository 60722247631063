<div class="content-wrapper">
    <div class="row m-3">
        <div class="col-lg-12 user-view--card grid-margin stretch-card">
            <div class="card mrr-card">
                <div class="card-body">
                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="d-flex justify-content-between">
                                <span></span>
                                <span class="userdetail-header">
                                    User Detail
                                </span>
                                <button class="btn btn-outline-brand" (click)="bsModalRef.hide()"><i class="fa fa-times"></i></button>

                            </div>
                        </div>
                        <!-- <div class="col-12">
                            <div class="d-flex justify-content-center">
                                User Detail
                            </div>
                            <div class="d-flex justify-content-right">
                                <button class="btn btn-outline-brand"><i class="fa fa-times"></i></button>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-sm-3 mb-5 property--image">
                            <img *ngIf="userDetail?.photoURL" [src]="userDetail?.photoURL" />
                            <img *ngIf="!userDetail?.photoURL" src="assets/logo/imgpsh_fullsize_anim.png" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card--info">
                                <div class="card-header">
                                    <h6>User Information</h6>
                                </div>
                                <div class="card-body">
                                    <table class="table card--table m-0 detail_table">
                                        <tr>
                                            <th>Name</th>
                                            <td>{{userDetail.firstName}} {{userDetail.lastName}}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{{userDetail.email}}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone</th>
                                            <td>{{userDetail.phone}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card card--info">
                                <div class="card-header">
                                    <h6>Bank Details</h6>
                                </div>
                                <div class="card-body">
                                    <table class="table card--table m-0 detail_table">
                                        <tr>
                                            <th>Name</th>
                                            <td>{{userDetail.bank.name}}</td>
                                        </tr>
                                        <tr>
                                            <th>Account</th>
                                            <td>{{userDetail.bank.account}}</td>
                                        </tr>
                                        <tr>
                                            <th>Provider Id</th>
                                            <td>{{userDetail.bank.provider_id}}</td>
                                        </tr>
                                        <tr>
                                            <th>Sort Code</th>
                                            <td>{{userDetail.bank['sort-code']}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!--<div class="col-sm-12">
                            <div class="card card--info mb-4">
                                <div class="card-header">
                                    <h6 class="m-0">Property Features</h6>
                                </div>
                                <div class="card-body">
                                    <table class="table m-0 detail_table">
                                        <tr *ngFor="let feature of propertDetail.feature">
                                            <td class="text-wrap">{{feature}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card card--info mb-4">
                                <div class="card-header">
                                    <h6 class="m-0">Property Description</h6>
                                </div>
                                <div class="card-body">
                                    <table class="table m-0 detail_table">
                                        <tr>
                                            <td class="text-wrap">{{propertDetail.desc}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                         <div class="col-sm-12">
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h6 class="m-0">confirm user Information</h6>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div *ngIf="!userDetail?.verified" class="verify-btn">
                                                <button type="submit" class="btn btn-md btn-outline-success btn-fw m-2" (click)="verifyUser(userDetail.id)">Mark user as verified</button>
                                            </div>
                                            <div *ngIf="userDetail?.verified" class="unverify-btn">
                                                <button type="submit" class="btn btn-md btn-outline-danger btn-fw m-2" (click)="unVerifyUser(userDetail.id)">Mark user as unverified</button>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <p>If you cannot confirm this user because of incomplete information or due to other reasons, please provide the reason in the text area and click the "Cannot Confirm" button</p>
                                            <form class="form-sample" [formGroup]="reasonForm" (submit)="onSubmit(userDetail)">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Reason</label>
                                                    <textarea *ngIf="userDetail" [(ngModel)]="userDetail.reason" class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="reason" name="reason"></textarea>
                                                    <div *ngIf="formSubmitted && reasonForm.controls.reason.errors" class="has-error">
                                                        <span *ngIf="reasonForm.controls.reason.errors.required">reason is required </span>
                                                    </div>
                                                </div>
                                                <button type="submit" class="btn btn-md btn-outline-danger btn-fw m-2">Cannot Confirm</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>