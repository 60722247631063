<div class="content-wrapper">
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card mrr-card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 d-flex justify-content-between">
              <div class="card--title-row">
                <h4 class="card-title">Payments</h4>
                <span class="totalnmbr">Total {{ newTotal }}</span>
                <div class="filter--user text-left mr-2"></div>
              </div>
              <div>
                <!-- <button
                  class="btn btn-outline-brand ms-auto"
                  (click)="removeLimit()"
                >
                  Load All
                </button> -->
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <div class="card--title-button">
                <button class="btn btn-create-payment" (click)="openModal()">
                  Create Payment
                </button>
              </div>
            </div> -->
          </div>
          <div class="table-responsive pt-3">
            <table class="table user-data-table">
              <thead>
                <tr>
                    <th
                      style="cursor: pointer"
                      (click)="getclickSortPayments()"
                    >
                    Created At<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          dateOrderDesc === false &&
                          sortvalue[0] === 'createdAt',
                        'fa-arrow-up':
                          dateOrderDesc === true && sortvalue[0] === 'createdAt'
                      }"
                    ></i>
                  </th>
                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('pardnaName')"
                  >
                    Pardna Name
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'pardnaName',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'pardnaName'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('amount')">
                    Amount
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'amount',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'amount'
                      }"
                    ></i>
                  </th>
                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('paymentStatus')"
                  >
                    Status
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' &&
                          sortvalue[0] == 'paymentStatus',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' &&
                          sortvalue[0] == 'paymentStatus'
                      }"
                    ></i>
                  </th>

                  <th style="cursor: pointer" (click)="sortLisitng('userName')">
                    User<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'userName',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'userName'
                      }"
                    ></i>
                  </th>
                  <th>Enviroment</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-template
                  *ngIf="payments?.length != 0"
                  ngFor
                  let-payment
                  [ngForOf]="
                    payments
                      | SortingFilter : sortvalue
                      | paginate
                        : {
                            itemsPerPage: 50,
                            currentPage: p,
                            totalItems: newTotal
                          }
                  "
                  let-i="index"
                >
                  <tr [ngClass]="{
                      'executed': payment?.paymentStatus == 'payment_executed',
                      'failed': payment?.paymentStatus == 'payment_failed'
                    }">
                    <td>
                      {{ changeDateFormat(payment?.createdAt) || "" }}
                    </td>
                    <td>
                      <a
                        class="user--name"
                        routerLink="/admin/pardna/{{ payment?.pardnaId }}"
                        target="_blank"
                      >
                        {{ payment?.pardnaName }}</a
                      >
                    </td>
                    <td>
                      {{ "£ " + (payment?.amount / 100).toFixed(2) || "" }}
                    </td>
                    <td >
                      <a
                        class="user--name"
                        routerLink="/admin/payment/{{ payment?.id }}"
                        target="_blank"
                      >
                        {{ payment?.paymentStatus || "" }}
                      </a>
                    </td>

                    <td>
                      <a
                        class="user--name"
                        routerLink="/admin/user/{{ payment?.userDocId }}"
                        target="_blank"
                        >{{ payment?.fullname}}</a
                      >
                    </td>
                    <td
                      [ngClass]="{
                        'sandbox-row': payment?.paymentMode == 'sandbox',
                        'live-row': payment?.paymentMode == 'live'
                      }"
                    >
                      {{ payment?.paymentMode }}
                    </td>
                    <td>
                      <button
                        class="btn btn-outline-brand"
                        (click)="deletePayment(payment.id)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </ng-template>
                <tr *ngIf="newTotal == 0">
                  <td colspan="6" class="text-center">No documents found !</td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="newTotal > 0" class="pagination mt-4 float-right">
              <pagination-controls
                (click)="clickPagination()"
                (pageChange)="getPage($event)"
                directionLinks="true"
                previousLabel="Previous"
                nextLabel="Next"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
