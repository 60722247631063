<div class="container w-100 payment-card px-sm-5">
  <div
    class="d-flex h-100 flex-column justify-content-center align-items-center text-center"
  >
    <div class="card border-0 shadow rounded p-sm-3 py-4 bg-white">
      <div
        class="card-body d-flex flex-column gap-3 justify-content-center align-items-center"
      >
        <img
          src="https://dashboard.pardna.com/assets/logo/imgpsh_fullsize_anim.png"
          class="mb-3"
          width="100px"
        />
        <h4 class="card-title mb-3">£ {{ amount.toFixed(2) }}</h4>
        <h5
          [ngClass]="{
            'executed card-text text-warning mb-3': status == 'executed',
            'cancelled card-text text-warning mb-3': status == 'cancelled',
            'pending card-text text-warning mb-3':
              status !== 'executed' && status !== 'cancelled'
          }"
        >
          GBP funding
          {{
            status == "executed"
              ? "completed"
              : status == "cancelled"
              ? "cancelled"
              : "pending"
          }}
        </h5>
        <p class="card-text mb-3 px-sm-5">
          Your transaction
          {{ status == "executed" ? "has been" : "is being" }} processed by your
          bank, you will receive a notification shortly.
        </p>
        <p class="card-text mb-3">{{ date }}</p>
        <div class="bg-light w-100 p-4 mt-3">
          <div class="d-flex justify-content-between gap-4">
            <span class="card-text text-muted">Status</span>
            <span class="card-text">{{ status }}</span>
          </div>
          <hr />
          <div class="d-flex justify-content-between gap-4">
            <span class="card-text text-muted">Payment ID</span>
            <span class="card-text">{{ _id }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-layout">
      <button
        class="btn btn-outline-danger py-2 px-4 my-3 mx-2"
        type="button"
        (click)="refreshPayment()"
      >
        Refresh Payment Status
      </button>
      <a class="btn btn-outline-danger py-2 px-4 my-3 mx-2" href="deeplink://">
        Return To Pardna App
      </a>
    </div>
  </div>
</div>
