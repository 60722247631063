import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-userdetail',
  templateUrl: './userdetail.component.html',
  styleUrls: ['./userdetail.component.scss']
})
export class UserdetailComponent implements OnInit {

  _id: any;
  userDetail;
  pardnaGroups;

  constructor(
    private router : Router,
    private alertnotification : AlertNotification,
    private apiServiceService : ApiServiceService,
    private route: ActivatedRoute,
    private firebaseservice : FirebaseQueryService,
    private loaderService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this._id = params.id;
    });

    this.getUserDetail(this._id);
  }

  async getUserDetail(id) {
    const charCount = id.length;
    if(charCount <= 21){
      await this.firebaseservice.getUserByDoc(id).then(function(response) {
        var data = response.data();
        data['id'] = response.id;
        this.userDetail = data;
        this.loaderService.hide();
      }.bind(this))
      .catch(function(error) {
        this.loaderService.hide();
        console.log("Error getting documents: ", error);
      }.bind(this));
    }else{
      var records1 = [];
      this.userDetail =await this.firebaseservice
        .getUserById(id)
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              records1.push(data);
            });
            this.loaderService.hide();
            return records1[0];
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
          }.bind(this)
        );
    }
    this.getPardnaGroups();
  }

  async getPardnaGroups(){
    var records1 = [];
    await this.firebaseservice.getUserPardnaGroups(this.userDetail.userId).then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        var data = doc.data();
        data['id'] = doc.id
        records1.push(data);
      })
      this.pardnaGroups = records1;
      console.log(this.pardnaGroups);
    }.bind(this))
    .catch(function(error) {
      this.loaderService.hide();
      console.log("Error getting documents: ", error);
    }.bind(this));
  }
}
