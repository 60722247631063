import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-pardna',
  templateUrl: './pardna.component.html',
  styleUrls: ['./pardna.component.scss']
})
export class PardnaComponent implements OnInit {

  userList:any = [];
  authUser: any = [];

  constructor(
    private router : Router,
    private alertnotification : AlertNotification,
    private apiServiceService : ApiServiceService
  ) { }

  ngOnInit(): void {
    // this.getAuthList();
    // this.getUserList();
  }
}

//   getUserList() {
//     this.apiServiceService.getRequest('getUsers').subscribe((response : any) => {
//       // console.log(response);
//       this.userList = response.map((x) => {
//         var data;
//         if(!x.paid){
//           data = x.members ? Object.keys(x.members) : [];
//           x['clientMemberArray'] = data;
//         }else{
//           var paidMember = x.paid ? Object.keys(x.paid) : [];
//           var members = x.members ? Object.keys(x.members) : [];
//           x['clientMemberArray'] = members.filter((x) => {
//             return (paidMember.indexOf(x) == -1);
//           })
//         }
//         return x;
//       });
//     },
//     error => {
//       this.alertnotification.errorAlert(error.error.message);
//       console.log(error.error.message)
//     })
//   }

//   getAuthList() {
//     this.apiServiceService.getRequest('getAuthUsers').subscribe((response) => {
//       // console.log(response)
//       this.authUser = response;
//     },
//     error => {
//       console.log(error)
//     })
//   }

//   setMemberPaid(memberId,user) {
//     Swal.fire({
//       title: 'Do you want to mark this user has paid?',
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Paid'
//     }).then((result) => {
//       // console.log(result);
//       if (result.value) {
//         // console.log(memberId, user);
//         var payment_pending = user.payment_pending ? user.payment_pending : [];
//         // console.log(payment_pending);
//         user.payments ? Object.keys(user.payments).map((x) => {
//           payment_pending.indexOf(x) == -1 ? payment_pending.push(x) : null;
//         }) : '';
//         // console.log(payment_pending);
//         // return;
//         var data = {
//           memberID : memberId,
//           PardnaId : user.id ? user.id : '',
//           paid : user.paid ? user.paid : {},
//           payInDueDate : moment().toDate(),
//           payOutDate : moment().toDate(),
//           pardnaName : user.name,
//           uid : user.uid,
//           payment_pending : payment_pending
//         }
//         this.apiServiceService.putRequest('updateUser',data).subscribe((response : any) => {
//           this.alertnotification.successAlert(response.message);
//           this.getUserList();
//         },
//         error => {
//           console.log(error);
//           this.alertnotification.errorAlert(error.message);
//         })
//       }else{
//         return;
//       }
//     })
//   }

//   sendEmail(uid){
//     var email = this.authUser.filter((x) => x.uid == uid);
//     return email.length > 0 ? email[0].email : '';
//   }

// }


// {
//   "id":"89a22829-0f74-4beb-b657-09da166dd816",
//   "recipients":1,
//   "external_id":null,
//   "errors":
//   {"invalid_player_ids":
//   ["67edf1d3-e4e3-4509-9df1-cd35dc13499b",
//   "82906c17-3ead-470b-a86b-8d6b258ff213",
//   "e661e08d-eae7-492f-a41b-6f8ec65c282f"
//   ]
// }