import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import * as _ from "lodash";
import * as moment from "moment";
import Swal from "sweetalert2";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { PaymentModalComponent } from "../payment-modal/payment-modal.component";
import { ApiServiceService } from "src/app/core/api-service/api-service";
@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent implements OnInit {
  paymentList;
  p: number = 1;
  sortvalue = ["createdAt", "desc"];
  sort: string = "asc";
  dateOrderDesc: boolean = true;
  total = "0";
  newTotal = 0;
  startAfter = undefined;
  sortOrd = 'desc'
  incrementFlag = true;
  payments: any = [];
  modalRef: BsModalRef;
  constructor(
    private fireBaseService: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private modalService: BsModalService,
    private httpClient: ApiServiceService,
    private firebaseservice: FirebaseQueryService,
  ) { }
  limit = true;
  ngOnInit(): void {
    this.getAllMongoPaymentsPaginated();
  }
  getPage(page) {
    if (this.p < page) {
      this.incrementFlag = false;
    } else {
      this.incrementFlag = true;
    }
    this.p = page;


    this.getAllMongoPaymentsPaginated(this.sortOrd);
  }


  async getAllMongoPayments() {
    try {
      const url = "paymentV3AllPayments";
      const aa = await this.httpClient.getRequestAuth(url).subscribe((data) => {
        this.payments = data;
      });
    } catch (error) {
      console.log(error);
    }
  }
  async getclickSortPayments() {

    if (this.sortOrd == 'desc') {
      this.sortOrd = 'asc';
    } else {
      this.sortOrd = 'desc';
    }
    this.getAllMongoPaymentsPaginated(this.sortOrd);
  }

  // async getAllMongoPaymentsPaginated(sortOrder = 'desc') {
  //   try {
  //     const url = `paymentV3AllPayments?page=${this.p}&&sortOrder=${sortOrder}&&limit=50`;
  //     const aa = await this.httpClient
  //       .getRequestAuth(url)
  //       .subscribe(async(data: any) => {
  //         this.newTotal = data.count;
  //         for (const payment of data.payments) { 
  //           payment.fullname = await  this.getFullName(payment.userId);
  //         } 
  //         this.payments = data.payments.reverse();
  //         console.log(this.payments);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  async getAllMongoPaymentsPaginated(sortOrder = 'desc') {
    try {
      const url = `paymentV3AllPayments?page=${this.p}&&sortOrder=${sortOrder}&&limit=50`;
      const data: any = await this.httpClient.getRequestAuth(url).toPromise();
      this.newTotal = data.count;
      await Promise.all(data.payments.map(async (payment) => {
        payment.fullname = await this.getFullName(payment.userId);
      }));
      this.payments = data.payments.reverse();
    } catch (error) {
      console.log(error);
    }
  }
  async getFullName(userId) {
    let userFullname;
    const result = await this.firebaseservice.getUserByEmail(userId);
    result.forEach((doc) => {
      const data = doc.data();
      const firstName = data.firstName || "";
      const lastName = data.lastName || "";
      userFullname = `${firstName} ${lastName}`.trim();
    });

    return userFullname;
  }

  clickPagination() {
    window.scroll(0, 0);
  }

  changeDateFormat(date) {
    return date && date.seconds
      ? moment.unix(date.seconds).format("MMM DD, YYYY")
      : date
        ? moment(date).isValid()
          ? moment(date).format("MMM DD, YYYY")
          : date
        : "";
  }

  sortLisitng(value) {
    if (!this.sortvalue.includes(value)) {
      this.sortvalue = [value, "desc"];
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [value, "desc"])
        : (this.sortvalue = [value, "asc"]);
    }
  }
  sortByDate(value) {
    if (value) {
      this.sortvalue = ["createdAt", "asc"];
      // asc
      let filtered1 = this.payments.filter((item) => !item.payments?.createdAt);
      let filtered = this.payments.filter((item) => item.payments?.createdAt);
      this.payments = _.orderBy(this.payments, ["createdAt"], ["asc"]);
      this.payments = [...this.payments, ...filtered];
      this.dateOrderDesc = !this.dateOrderDesc;
    } else {
      // desc
      this.sortvalue = ["createdAt", "desc"];
      let filtered1 = this.payments.filter((item) => !item.payments?.updatedAt);
      let filtered = this.payments.filter((item) => item.payments?.updatedAt);
      this.payments = _.orderBy(this.payments, ["createdAt"], ["desc"]);
      this.payments = [...this.payments, ...filtered];
      this.dateOrderDesc = !this.dateOrderDesc;
    }
  }

  deletePayment(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "This will delete the payment!",
      icon: "warning",
      showLoaderOnConfirm: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.fireBaseService.getPaymentByRef(id).then((querySnapshot) => {
            const data = querySnapshot.data();
            this.fireBaseService
              .deletePayment(id)
              .then(() => {
                this.fireBaseService
                  .updatePardna(data.pardnaId, data.userId)
                  .then(() => {
                    this.loaderService.hide();
                    Swal.fire("Payment Deleted Successfully!").then(() => {
                      this.getPage(this.p);
                      // this.getCount();
                    });
                  })

                  .catch(() => {
                    this.loaderService.hide();
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Unable to delete payment!",
                    });
                  });
              })
              .catch(() => {
                this.loaderService.hide();
                Swal.fire({
                  icon: "error",
                  title: "Osops...",
                  text: "Unable to delete payment!",
                });
              });
            this.loaderService.hide();
          });
        }
      })
      .catch((error) => {
        this.loaderService.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Payment Does not exist",
        });
      });
  }

  openModal() {
    this.modalRef = this.modalService.show(PaymentModalComponent);
  }
}
