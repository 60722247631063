<div class="viewModal">
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
  <div class="mb-2">
    <h3>Your email</h3>
    <p>{{ modalData.application["email?"] }}</p>
  </div>
  <div class="mb-2">
    <h3>
      How many people are in the pardna group/s you manage? Please note you need
      to have more than 12 people to become an affiliate.
    </h3>
    <p>
      {{
        modalData.application[
          "How many people are in the pardna group/s you manage? Please note you need to have more than 12 people to become an affiliate.?"
        ]
      }}
    </p>
  </div>
  <div class="mb-2">
    <h3>
      How are you managing the admin of your group/s? (e.g WhatsApp, Excel,
      Email etc)
    </h3>
    <p>
      {{
        modalData.application[
          "How are you managing the admin of your group/s? (e.g WhatsApp, Excel, Email etc) ?"
        ]
      }}
    </p>
  </div>
  <div class="mb-2">
    <h3>How do you collect and distribute the pardna Hand and Draw?</h3>
    <p>
      {{
        modalData.application[
          "How do you collect and distribute the pardna Hand and Draw?"
        ]
      }}
    </p>
  </div>
  <div class="mb-2">
    <h3>Do you want to earn a commission from each person you bring in?</h3>
    <p>
      {{
        modalData.application[
          "Do you want to earn a commission from each person you bring in?"
        ]
      }}
    </p>
  </div>

  <div>
    <h3>Any other comments?</h3>
    <p class="mb-0">{{ modalData.application["Any other comments?"] }}</p>
  </div>
</div>
