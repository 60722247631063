import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { ApiServiceService } from "src/app/core/api-service/api-service";

@Component({
  selector: "app-payment-success",
  templateUrl: "./payment-success.component.html",
  styleUrls: ["./payment-success.component.scss"],
})
export class PaymentSuccessComponent implements OnInit {
  _id: any;
  paymentDetails = undefined;
  amount =0;
  date;
  status;

  constructor(
    private route: ActivatedRoute,
    private fireBaseService: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private httpClient: ApiServiceService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      this._id = params.payment_id;
      console.log(this._id);
      this.loaderService.show();
      this.getPymentDetail(this._id);
    });
  }
  getPymentDetail(id) {
    try {
      setTimeout(() => {
        console.log('hello');
        this.fireBaseService.getPaymentByRef(id).then((querySnapshot) => {
          const data = querySnapshot.data();
              console.log("===>",data);
              this.paymentDetails = data;
              console.log(123, this.paymentDetails);
              this.amount =
              this.paymentDetails?.apiResponse?.results[0]?.amount / 100;
              this.date = this.paymentDetails?.apiResponse?.results[0]?.created_at;
              this.date = new Date(this.date);
              this.status = this.paymentDetails?.paymentStatus;
              this.loaderService.hide();
            })
            .catch((err) => {
                this.loaderService.hide();
            });
    }, 150);
      
    } catch (error) {
      console.log(error);
    }
  }

  refreshPayment() {
    console.log("called",this.paymentDetails);
    const userId = this.paymentDetails.userId;
    const pardnaId = this.paymentDetails.pardnaId;
    const paymentId = this.paymentDetails.paymentId;
    const url =
      this.paymentDetails.paymentMode == "sandbox"
        ? `paymentSuccessfulSandbox?userId=${userId}&pardnaId=${pardnaId}&paymentId=${paymentId}`
        : `paymentSuccessful?userId=${userId}&pardnaId=${pardnaId}&paymentId=${paymentId}`;
    this.httpClient
      .postRequest(url, {
        userId,
        pardnaId,
        paymentId,
      })
      .subscribe((res: any) => {
        console.log({ res });
        this.status = res?.results[0]?.status;
      });
  }
}
