<div class="modal-header">
  <h4 class="modal-title">Create Payment</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>
<div class="">
  <form class="pt-3" [formGroup]="registerForm" (submit)="onSubmit()">
    <table class="table m-2 detail_table table-borderless">
      <tr>
        <th>Amount</th>
        <td>
          <input
            type="number"
            class="form-control"
            name="amount"
            formControlName="amount"
          />
          <div
            *ngIf="formSubmitted && registerForm.controls.amount.errors"
            class="has-error"
          >
            <span *ngIf="registerForm.controls.amount.errors.required"
              >Amount is required</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <th>Type</th>
        <td>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="live"
                formControlName="type"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Live
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="sandbox"
                formControlName="type"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Sandbox
              </label>
            </div>
          </div>

          <div
            *ngIf="formSubmitted && registerForm.controls.type.errors"
            class="has-error"
          >
            <span *ngIf="registerForm.controls.type.errors.required"
              >Type is required</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <th>
          <button type="button" class="btn btn-danger" (click)="close()">
            Close
          </button>
        </th>
        <td class="align">
          <button type="submit" class="btn btn-create-payment">Create</button>
        </td>
      </tr>
    </table>
  </form>
</div>
