<div class="container-scroller">
    <div class="horizontal-menu">
        <app-header></app-header>
        <app-nav-bar-top></app-nav-bar-top>
    </div>

    <div class="container page-body-wrapper">
        <div class="main-panel">
            <router-outlet></router-outlet>
            <app-footer class="footer"></app-footer>
        </div>
    </div>
</div>