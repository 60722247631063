import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";

@Component({
  selector: "app-payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.scss"],
})
export class PaymentDetailsComponent implements OnInit {
  paymentDetails = undefined;
  _id: any;
  constructor(
    private fireBaseService: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this._id = params.id;
    });
    this.getPaymentDetails(this._id);
  }

  async getPaymentDetails(id) {
    try {
      this.fireBaseService
        .getPaymentByRef(id)
        .then((querySnapshot) => {
          this.paymentDetails = querySnapshot.data();
          console.log(123, this.paymentDetails);
        })
        .catch((err) => {
          this.loaderService.hide();
        });
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
    }
  }
  changeDateFormat(date) {
    return date && date.seconds
      ? moment.unix(date.seconds).format("MMM DD, YYYY")
      : date
      ? moment(date).isValid()
        ? moment(date).format("MMM DD, YYYY")
        : date
      : "";
  }
}
