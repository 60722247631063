import { Pipe, PipeTransform } from "@angular/core";
import { AlertNotification } from "../../core/alertnotification/alertNotification";
import * as moment from "moment";

@Pipe({
  name: "SortingFilter",
})
export class SortUserListPipe implements PipeTransform {
  constructor(private notifier: AlertNotification) {}

  transform(allUsers: any, term: any) {
    if (term.length > 1 && allUsers && allUsers.length) {
      var users = allUsers.sort(function (a, b) {
        const field = term[0];
        if (term[0] == "createdAt") {
          if (a.createdAt && b.createdAt) {
            var field1: any = moment.unix(a.createdAt["_seconds"]).format();
            var field2: any = moment.unix(b.createdAt["_seconds"]).format();
            if (term[1] == "asc") {
              return field1 > field2 ? 1 : -1;
            } else {
              return field2 > field1 ? 1 : -1;
            }
          } else {
            return;
          }
        } else if (term[0] == "payout") {
          if (a.payout && b.payout) {
            var field1: any = a.payout.substring(0, a.payout.indexOf("£"));
            var field2: any = b.payout.substring(0, b.payout.indexOf("£"));
            if (term[1] == "asc") {
              return field1 > field2 ? 1 : -1;
            } else {
              return field2 > field1 ? 1 : -1;
            }
          } else {
            return;
          }
        } else if (
          term[0] == "months" ||
          term[0] == "amount" ||
          term[0] == "people" ||
          term[0] == "commission" ||
          term[0] == "sortNumber" ||
          term[0] == "user"
        ) {
          var field1: any = a[field] || 0;
          var field2: any = b[field] || 0;
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        } else if (term[0] == "bankName") {
          var field1: any = a.bank["name"] ? a.bank["name"].toLowerCase() : "";
          var field2: any = b.bank["name"] ? b.bank["name"].toLowerCase() : "";
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        } else if (term[0] == "account") {
          var field1: any = a.bank["account"] || 0;
          var field2: any = b.bank["account"] || 0;
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        } else if (term[0] == "sortCode") {
          var field1: any = (a.sortCode && a.sortCode.split("-")[0]) || 0;
          var field2: any = (b.sortCode && b.sortCode.split("-")[0]) || 0;
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        } else if (term[0] == "firstName") {
          var field1: any = a.user?.["firstName"]
            ? a.user["firstName"].toLowerCase()
            : "";
          var field2: any = b.user?.["firstName"]
            ? b.user["firstName"].toLowerCase()
            : "";
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        } else if (term[0] == "status") {
          var field1: any = a.apiResponse?.results[0]?.["status"]
            ? a.apiResponse?.results[0]["status"].toLowerCase()
            : "";
          var field2: any = b.apiResponse?.results[0]?.["status"]
            ? b.apiResponse?.results[0]["status"].toLowerCase()
            : "";
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        } else if (term[0] == "paymentAmount") {
          var field1: any = a?.apiResponse?.results[0].amount || 0;
          var field2: any = b?.apiResponse?.results[0].amount || 0;
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        } else if (term[0] == "pardnaName") {
          var field1: any = a?.pardna?.name?.toLowerCase() || "";
          var field2: any = b?.pardna?.name?.toLowerCase() || "";
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        } else {
          var field1 = a[field] ? a[field].toLowerCase() : "";
          var field2 = b[field] ? b[field].toLowerCase() : "";
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        }
      });
      return users;
    } else {
      return allUsers;
    }
  }

  filterValueSendTo(field) {
    if (field.type == "All") {
      return "All";
    } else if (field.type !== "All" && !field.users.users) {
      return "";
    } else if (field.type !== "All" && field.users && field.users.users) {
      if (field.users.users.length > 1) {
        return "Group";
      } else {
        return field.users.users[0].email;
      }
    }
  }
}
