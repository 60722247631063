import { Component, OnInit } from "@angular/core";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
// import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from "ngx-spinner";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { pardnaModalComponent } from "../pardna-modal/pardna-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import * as _ from "lodash";
import * as moment from "moment";
@Component({
  selector: "app-affiliate",
  templateUrl: "./affiliate.component.html",
  styleUrls: ["./affiliate.component.scss"],
})
export class AffiliateComponent implements OnInit {
  statsValue: any = {};
  affiliates: any = [];
  sortvalue = ["createdAt", "desc"];
  sort: string = "asc";
  dateOrderDesc: boolean = true;
  incrementFlag = true;
  modalRef: BsModalRef;
  p: number = 1;
  newTotal = 0;
  constructor(
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private modalService: BsModalService,
    private httpClient: ApiServiceService,

  ) { }

  ngOnInit(): void {
    this.getAllAffilates();

  }

  getPage(page) {
    if (this.p < page) {
      this.incrementFlag = false;
    } else {
      this.incrementFlag = true;
    }
    this.p = page;
    this.getAllAffilates();
  }
 
  async getAllAffilates() {
    try {
      const querySnapshot = await this.firebaseservice.getAffiliates();
      const affiliates = [];
      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        data['id'] = doc.id;
        data['email'] = await this.getUserEmail(data.userId);
        affiliates.push(data);
      }
      this.statsValue["affiliates"] = affiliates.length;
      this.newTotal = affiliates.length;
      this.affiliates = affiliates;
     
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
      console.error("Error getting documents: ", error);
    }
  }
  async  getUserEmail(userId) {
    console.log(userId);
    let userEmail;
    const result = await this.firebaseservice.getUserByEmail(userId);
    result.forEach((doc) => {
      const data = doc.data();
      userEmail= data.email
    });
    return userEmail;
  }
  clickPagination() {
    window.scroll(0, 0);
  }

  changeDateFormat(date) {
    return date && date.seconds
      ? moment.unix(date.seconds).format("MMM DD, YYYY")
      : date
        ? moment(date).isValid()
          ? moment(date).format("MMM DD, YYYY")
          : date
        : "";
  }

  sortLisitng(value) {
    if (!this.sortvalue.includes(value)) {
      this.sortvalue = [value, "desc"];
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [value, "desc"])
        : (this.sortvalue = [value, "asc"]);
    }
  }
  sortByDate(value) {
    if (value) {
      this.sortvalue = ["createdAt", "asc"];
      // asc
      let filtered1 = this.affiliates.filter((item) => !item.affiliates?.createdAt);
      let filtered = this.affiliates.filter((item) => item.affiliates?.createdAt);
      this.affiliates = _.orderBy(this.affiliates, ["createdAt"], ["asc"]);
      this.affiliates = [...this.affiliates, ...filtered];
      this.dateOrderDesc = !this.dateOrderDesc;
    } else {
      // desc
      this.sortvalue = ["createdAt", "desc"];
      let filtered1 = this.affiliates.filter((item) => !item.affiliates?.updatedAt);
      let filtered = this.affiliates.filter((item) => item.affiliates?.updatedAt);
      this.affiliates = _.orderBy(this.affiliates, ["createdAt"], ["desc"]);
      this.affiliates = [...this.affiliates, ...filtered];
      this.dateOrderDesc = !this.dateOrderDesc;
    }
  }
  async openModal(documentId) {
    const doc = await this.firebaseservice.getAffiliatebyId(documentId);
    const initialState = { modalData: doc.data() };
    this.modalRef = this.modalService.show(pardnaModalComponent, { initialState });
    this.loaderService.hide();
  }
  async approve(documentId, status, userId) {

    await this.firebaseservice.approveAffiliteById(documentId, status);
    await this.firebaseservice.approveAffiliateByUserId(userId, status);
    this.loaderService.hide();
    this.getAllAffilates();
  }
 
}
