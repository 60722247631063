import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import * as firebase from 'firebase';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  registerForm: FormGroup;
  formSubmitted = false;
  
  constructor(
    private formBuilder : FormBuilder,
    private router : Router,
    private alertnotification : AlertNotification,
    private apiServiceService : ApiServiceService,
    private firebaseservice : FirebaseQueryService
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
    {
      email : [null,[Validators.required,Validators.email]],
      password : [null,[Validators.required]]
    })
  }

  onSubmit() {
    this.formSubmitted = true;
    localStorage.removeItem('token');
    // console.log(this.registerForm.value.email != 'admin@pardna.com');
    // console.log(this.registerForm.value.email != '123123');
    if (this.registerForm.invalid) {
      return;
    }
    else if(this.registerForm.value.email != 'admin@pardna.com') {
      this.alertnotification.errorAlert('Unauthorised User');
      return;
    }
    else{
      console.log(this.registerForm.value);
      // User Sign In
      this.firebaseservice.signInUser(this.registerForm.value.email,this.registerForm.value.password).then(function (response){
        localStorage.setItem('User', JSON.stringify(response));
        // Get Current User TokenID
        firebase.auth().currentUser.getIdToken().then(function(token){
          console.log(token);
          localStorage.setItem('token', JSON.stringify(token));
        })
        this.alertnotification.successAlert('Signed In Successfully');
        this.router.navigate(['admin/dashboard']);
      }.bind(this))
      .catch(function(error) {
        this.alertnotification.errorAlert(error);
        return;
      }.bind(this));
    }

  }

  errorMessageAlert(error){
    this.alertnotification.errorAlert(error);
  }

}
