import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { SortUserListPipe } from './pipes-function/sortingPipe';
import { TruncatePipe } from './pipes-function/truncatePipe';
import { UserDetailComponent } from './user-detail/user-detail.component';



@NgModule({
  declarations: [SortUserListPipe , TruncatePipe, UserDetailComponent],
  imports: [
    CommonModule,
    SharedRoutingModule
  ],
  exports : [SortUserListPipe , TruncatePipe],
  entryComponents : [UserDetailComponent]
})
export class SharedModule { }
