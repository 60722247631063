import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PardnaComponent } from "./pardna/pardna.component";
import { ActivePardnasComponent } from "./active-pardnas/active-pardnas.component";
import { PardnaDetailsComponent } from "./pardna-details/pardna-details.component";
import { UserlistComponent } from "./userlist/userlist.component";
import { UserdetailComponent } from "./userdetail/userdetail.component";
import { PaymentsComponent } from "./payments/payments.component";
import { PaymentDetailsComponent } from "./payment-details/payment-details.component";
import { AffiliateComponent } from "./affiliate/affiliate.component";

const routes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    children: [
      { path: "dashboard", component: DashboardComponent },
      // { path :'pardna' , component : PardnaComponent },
      { path: "activepardna", component: ActivePardnasComponent },
      { path: "users", component: UserlistComponent },
      { path: "payments", component: PaymentsComponent },
      { path: "pardna/:id", component: PardnaDetailsComponent },
      { path: "payment/:id", component: PaymentDetailsComponent },
      { path: "user/:id", component: UserdetailComponent },
      { path: "affiliate", component: AffiliateComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
