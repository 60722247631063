<div class="content-wrapper">
  <div class="row">
    <div class="col-lg-12 user-view--card grid-margin stretch-card">
      <div class="card mrr-card">
        <div class="card-body">
          <!-- <div class="row">
                        <div class="col-sm-2 mb-5 property--image">
                            <img style="cursor: pointer;" (click)="showImage(propertDetail?.image,propertDetail.title)" *ngIf="propertDetail?.image" [src]="propertDetail.image" />
                            <img *ngIf="!propertDetail?.image" src="assets/images/icon_gradiant.png" />
                        </div>
                    </div> -->
          <div class="row">
            <div class="col-sm-12">
              <div class="card card--info">
                <div class="card-header">
                  <h6>{{ pardnaDetails ? pardnaDetails.name : "" }}</h6>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr>
                      <!-- <th>Title</th> -->
                      <td>
                        Created By
                        {{ pardnaDetails ? pardnaDetails.createdByName : "" }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="card card--info">
                <div class="card-header">
                  <h6>Pardna Information</h6>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr>
                      <th>People</th>
                      <td>{{ pardnaDetails ? pardnaDetails.people : "" }}</td>
                    </tr>
                    <tr>
                      <th>Months</th>
                      <td>{{ pardnaDetails ? pardnaDetails.months : "" }}</td>
                    </tr>
                    <tr>
                      <th>Payout</th>
                      <td>{{ pardnaDetails ? pardnaDetails.payout : "" }}</td>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td>
                        {{
                          pardnaDetails && pardnaDetails.amount
                            ? "£" + pardnaDetails.amount
                            : ""
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="cardMember card card--info">
                <div class="card-header">
                  <h6>Pardna Members</h6>
                </div>
                <div class="card-body">
                  <table class="table card--table cardMember m-0 detail_table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Paid into Group</th>
                        <th>Email</th>
                        <th>Payment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-template
                        *ngIf="
                          pardnaDetails &&
                          pardnaDetails.clientMemberArray?.length != 0
                        "
                        ngFor
                        let-pardna
                        [ngForOf]="pardnaDetails.clientMemberArray"
                        let-i="index"
                      >
                        <tr>
                          <td>
                            <div class="userthumbnail">
                              <span class="user-thumb user--image">
                                <img
                                  *ngIf="
                                    pardna?.userdetail != undefined &&
                                    pardna?.userdetail.photoURL
                                  "
                                  [src]="pardna?.userdetail.photoURL"
                                />
                                <img
                                  *ngIf="
                                    !pardna?.userdetail ||
                                    !pardna?.userdetail?.photoURL
                                  "
                                  src="assets/logo/imgpsh_fullsize_anim.png"
                                />
                              </span>
                              <div class="cell">
                                <a
                                  (click)="userInfo(pardna?.userdetail)"
                                  class="user--name"
                                  >{{ pardna?.userdetail?.firstName }}
                                  {{ pardna?.userdetail?.lastName }}</a
                                >
                              </div>
                            </div>
                          </td>
                          <td class="center-text">
                            <span   *ngIf="pardnaDetails?.payments && pardnaDetails?.payments[pardna?.userdetail?.userId] " class="text-green">Paid</span>
                          </td>
                          
                          <td>
                            <button
                              class="btn btn-create-payment"
                              (click)="openModal(pardna?.userdetail)"
                            >
                              Create Payment
                            </button>
                          </td>
                          <td>
                            <!-- <button *ngIf="pardna && (!pardna.paid || pardna.paid == 'paid')" class="btn btn-outline-brand" type="button" [disabled]="true">PAID</button> -->
                            <button
                              *ngIf="
                                pardna && pardna.paid && pardna.paid !== 'paid'
                              "
                              (click)="setMemberPaid(pardna.id, pardnaDetails)"
                              class="btn btn-outline-brand"
                            >
                              Mark as Paid
                            </button>
                          </td>
                        </tr>
                      </ng-template>
                      <tr
                        *ngIf="
                          pardnaDetails &&
                          pardnaDetails.clientMemberArray?.length == 0
                        "
                      >
                        <td colspan="6" class="text-center">
                          No documents found !
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-12">
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h6 class="m-0">confirm user Information</h6>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div *ngIf="!userDetail?.verified" class="verify-btn">
                                                <button type="submit" class="btn btn-md btn-outline-success btn-fw m-2" (click)="verifyUser(userDetail.id)">Mark user as verified</button>
                                            </div>
                                            <div *ngIf="userDetail?.verified" class="unverify-btn">
                                                <button type="submit" class="btn btn-md btn-outline-danger btn-fw m-2" (click)="unVerifyUser(userDetail.id)">Mark user as unverified</button>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <p>If you cannot confirm this user because of incomplete information or due to other reasons, please provide the reason in the text area and click the "Cannot Confirm" button</p>
                                            <form class="form-sample" [formGroup]="reasonForm" (submit)="onSubmit(userDetail)">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Reason</label>
                                                    <textarea *ngIf="userDetail" [(ngModel)]="userDetail.reason" class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="reason" name="reason"></textarea>
                                                    <div *ngIf="formSubmitted && reasonForm.controls.reason.errors" class="has-error">
                                                        <span *ngIf="reasonForm.controls.reason.errors.required">reason is required </span>
                                                    </div>
                                                </div>
                                                <button type="submit" class="btn btn-md btn-outline-danger btn-fw m-2">Cannot Confirm</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
