<div class="content-wrapper">
  <div class="row">
    <div class="col-lg-12 user-view--card grid-margin stretch-card">
      <div class="card mrr-card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="card card--info">
                <div class="card-header">
                  <h6>Payment Information</h6>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr>
                      <th>Status</th>
                      <td>
                        {{
                          paymentDetails
                            ? paymentDetails.apiResponse.results[0].status
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Beneficiary Name</th>
                      <td>
                        {{
                          paymentDetails
                            ? paymentDetails.apiResponse.results[0]
                                .beneficiary_name
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Created At</th>
                      <td>
                        {{
                          paymentDetails
                            ? changeDateFormat(
                                paymentDetails.apiResponse.results[0].created_at
                              )
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td>
                        {{
                          paymentDetails &&
                          paymentDetails.apiResponse.results[0].amount
                            ? "£" +
                              paymentDetails.apiResponse.results[0].amount / 100
                            : ""
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
