import { Component, OnDestroy, OnInit ,Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
@Component({
  selector: "app-pardna-modal",
  templateUrl: "./pardna-modal.component.html",
  styleUrls: ["./pardna-modal.component.scss"],
})
export class pardnaModalComponent implements OnInit /*OnDestroy*/ {
  @Input() modalData: any;  
  constructor(
    public bsModalRef: BsModalRef,
  ) {}
  ngOnInit(): void {
    
  }
  close() {
    this.bsModalRef.hide();
  }
  
}
