import { Component, OnInit } from "@angular/core";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
// import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from "ngx-spinner";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  statsValue: any = {};
  payments: number = 0;
   affilates: number =0;
  constructor(
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private httpClient: ApiServiceService
  ) {}

  ngOnInit(): void {
    this.getUserlist();
    this.getPardnasList();
    // this.getPaymentsList();
    this.getAllMongoPayments();
    this.getAffiltes();
  }

  getPardnasList() {
    try {
      var records1 = [];
      this.firebaseservice
        .getPardna()
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              records1.push(data);
            });
            this.statsValue["pardnas"] = records1.length;
           
            this.loaderService.hide();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
          }.bind(this)
        );
    } catch (error) {
      this.loaderService.hide();
      console.log(error);
    }
  }

  getUserlist() {
    try {
      var records1 = [];
      this.firebaseservice
        .getUsers()
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              records1.push(data);
            });
            this.statsValue["users"] = records1.length;
            this.loaderService.hide();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
          }.bind(this)
        );
    } catch (error) {
      this.loaderService.hide();
      console.log(error);
    }
  }

  getPaymentsList() {
    try {
      var payments = [];
      this.firebaseservice
        .getPayments()
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              payments.push(data);
            });
            this.statsValue["payments"] = payments.length;
            this.loaderService.hide();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
          }.bind(this)
        );
    } catch (error) {
      this.loaderService.hide();
      console.log(error);
    }
  }

  async getAllMongoPayments() {
    try {
      const url = `paymentV3AllPayments?page=1&&limit=50`;
      const aa = await this.httpClient
        .getRequestAuth(url)
        .subscribe((data: any) => {
          this.payments = data.count;
        });
    } catch (error) {
      console.log(error);
    }
  }
  getAffiltes() {
    try {
      var affilates = [];
      this.firebaseservice
        .getAffiliates()
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              affilates.push(data);
            });
            
            this.statsValue["affilates"] = affilates.length;
           
            this.loaderService.hide();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
          }.bind(this)
        );
    } catch (error) {
      this.loaderService.hide();
      console.log(error);
    }
  }

}
