import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, finalize } from "rxjs/operators";
import { AlertNotification } from "../alertnotification/alertNotification";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private alertnotification : AlertNotification,
    private spinner: NgxSpinnerService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    const remember_me = ''
    if (token) {
      let tokens = JSON.parse(token);
      request = request.clone({
        headers: request.headers.set("Authorization",tokens)
      });
    }else{
      request = request.clone({
        headers: request.headers.set("Authorization",'')
      });
    }
    //check remember
    if (remember_me) {
      request = request.clone({
        headers: request.headers.set("remember", remember_me)
      });
    }
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if(error.status == 400) {
          }
          if (error.status === 409) {
            return;
          }
          if (error.status === 405) {
            this.alertnotification.errorAlert(error.error.message._message);
            // return;
          }
          if (error.status === 417) {
            return;
          }
          if (error.status === 429) {
            return;
          }
          if (error.status === 413) {
            this.alertnotification.errorAlert('payload request size too large');
            return;
          }
          /** Server Validation Errors **/
          if (error.status == 404) {
            // console.log(error.error.message.length);
            const errors = error.error.message;
              const err = error.error.message.length;
              const index = 0;
              for (let index = 0; index < err; index++) {
                  this.alertnotification.errorAlert(errors[index].msg);
              }
              return;
            }
          if (error.status === 401) {
            localStorage.removeItem('User');
            localStorage.removeItem('token');
            this.router.navigate(["/"]);
          }
        }else{
          // console.log("vbjv")
        }
        return throwError(error);
      }
      )
    );
  }
}

export const HttpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true
};
