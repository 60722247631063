import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';

@Component({
  selector: 'app-active-pardnas',
  templateUrl: './active-pardnas.component.html',
  styleUrls: ['./active-pardnas.component.scss']
})
export class ActivePardnasComponent implements OnInit {

  userList = [];
  userListCopy = [];
  sortvalue=['createdAt','desc'];
  sort: string = 'asc';
  p: number = 1;
  statusArray = [
    {name : 'Started' , id:"started"},
    {name : 'Ended' , id:"ended"},
    {name : 'Pending' , id:"pending"}
  ]
  sortDataArray: any;
  statusFilterArray = ['Live Pardnas','Test Pardnas'];

  constructor(
    private router : Router,
    private alertnotification : AlertNotification,
    private firebaseservice : FirebaseQueryService,
    private apiServiceService : ApiServiceService
  ) { }

  ngOnInit(): void {
    this.getUserList();
  }

  getUserList() {
    this.apiServiceService.getRequest('getUsers').subscribe((response : any) => {
      this.userList = response;
      this.userListCopy = response;
      this.sortDataArray = response;
      // console.log(response);
      // this.userList = response.map((x) => {
      //   var data;
      //   if(!x.paid){
      //     data = x.members ? Object.keys(x.members) : [];
      //     x['clientMemberArray'] = data;
      //   }else{
      //     var paidMember = x.paid ? Object.keys(x.paid) : [];
      //     var members = x.members ? Object.keys(x.members) : [];
      //     x['clientMemberArray'] = members.filter((x) => {
      //       return (paidMember.indexOf(x) == -1);
      //     })
      //   }
      //   return x;
      // });
    },
    error => {
      this.alertnotification.errorAlert(error.error.message);
      console.log(error.error.message)
    })
  }

  clickPagination(){
    window.scroll(0,0)
  }

  sortLisitng(value) {
    if(!this.sortvalue.includes(value)){
      this.sortvalue = [value , this.sort];
    }else{
      this.sortvalue[1] == this.sort ? this.sortvalue = [value , 'desc'] : this.sortvalue = [value , 'asc'];
    }
  }

  convertDate(date) {
    return date._seconds ? moment.unix(date._seconds).format('MMM DD, YYYY') : '';
  }

  changeStatus(evt) {
    // console.log(evt)
    if(evt) {
      this.userList = this.sortDataArray.filter((x) => {
        return evt.id == x.status
      })
    }else{
      this.userList = this.sortDataArray;
    }
  }
  updatePardnaSandboxStatus(evt, id){
    let index =  this.userList.findIndex(x => x.id === id);
    this.userList[index].sandbox = evt.target.checked;
    let indexCopy =  this.userListCopy.findIndex(x => x.id === id);
    this.userListCopy[indexCopy].sandbox = evt.target.checked;
    this.firebaseservice.updatePardnaSandbox(id,evt.target.checked);
  }
  onChangestatusFilter(evt){    
    this.userList = this.userListCopy.map(x => x);
    this.p = 1;
    if(evt === this.statusFilterArray[0]){
      // live users
      this.userList = this.userListCopy.filter((x) => {
        if (!x.sandbox) {
          return x;
        }
      })
    }else if (evt === this.statusFilterArray[1]){
      // test users
      this.userList = this.userListCopy.filter((x) => {
        if (x.sandbox) {
          return x;
        }
      })
    }
  }
}
