import { Component, OnInit } from "@angular/core";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
// import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import * as _ from "lodash";

@Component({
  selector: "app-userlist",
  templateUrl: "./userlist.component.html",
  styleUrls: ["./userlist.component.scss"],
})
export class UserlistComponent implements OnInit {
  userlistArray;
  userListArrayCopy;
  sortvalue = ["createdAt", "desc"];
  sort: string = "asc";
  p: number = 1;
  statusFilterArray = ["Live Users", "Test Users"];
  dateOrderDesc: boolean = true;

  constructor(
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getUserlist();
  }

  async getUserlist() {
    try {
      var records1 = [];
      await this.firebaseservice
        .getUsers()
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              records1.push(data);
            });
            this.userlistArray = records1.map((x) => {
              x["sortCode"] =
                x.bank && x.bank["sort-code"] ? x.bank["sort-code"] : "";
              x.bank && x.bank["sort-code"] ? delete x.bank["sort-code"] : "";
              return x;
            });
            this.userListArrayCopy = records1.map((x) => {
              x["sortCode"] =
                x.bank && x.bank["sort-code"] ? x.bank["sort-code"] : "";
              x.bank && x.bank["sort-code"] ? delete x.bank["sort-code"] : "";
              return x;
            });
            let filtered = this.userlistArray.filter((item) => !item.createdAt);
            let filtered1 = this.userlistArray.filter((item) => item.createdAt);
            this.userlistArray = _.orderBy(
              filtered1,
              ["createdAt.seconds"],
              ["desc"]
            );
            this.userlistArray = [...this.userlistArray, ...filtered];
            console.log(this.userlistArray);
            this.loaderService.hide();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
          }.bind(this)
        );
    } catch (error) {
      this.loaderService.hide();
      console.log(error);
    }
    // this.dateOrderDesc = true;
    // await this.sortByDate(this.dateOrderDesc);
    // this.dateOrderDesc = !this.dateOrderDesc;
  }

  clickPagination() {
    window.scroll(0, 0);
  }

  sortLisitng(value) {
    if (!this.sortvalue.includes(value)) {
      this.sortvalue = [value, this.sort];
      console.log(this.sortvalue);
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [value, "desc"])
        : (this.sortvalue = [value, "asc"]);
    }
  }

  sortByDate(value) {
    if (value) {
      // asc
      let filtered = this.userlistArray.filter((item) => !item.createdAt);
      let filtered1 = this.userlistArray.filter((item) => item.createdAt);
      this.userlistArray = _.orderBy(filtered1, ["createdAt.seconds"], ["asc"]);
      this.userlistArray = [...this.userlistArray, ...filtered];
      this.dateOrderDesc = !this.dateOrderDesc;
    } else {
      // desc

      let filtered = this.userlistArray.filter((item) => !item.createdAt);
      let filtered1 = this.userlistArray.filter((item) => item.createdAt);
      this.userlistArray = _.orderBy(
        filtered1,
        ["createdAt.seconds"],
        ["desc"]
      );
      this.userlistArray = [...this.userlistArray, ...filtered];
      this.dateOrderDesc = !this.dateOrderDesc;
    }
  }

  changeDateFormat(date) {
    return date && date.seconds
      ? moment.unix(date.seconds).format("MMM DD, YYYY")
      : date
      ? moment(date).isValid()
        ? moment(date).format("MMM DD, YYYY")
        : date
      : "";
  }

  updateUserSandboxStatus(evt, id) {
    let index = this.userlistArray.findIndex((x) => x.id === id);
    this.userlistArray[index].sandbox = evt.target.checked;
    let indexCopy = this.userListArrayCopy.findIndex((x) => x.id === id);
    this.userListArrayCopy[indexCopy].sandbox = evt.target.checked;
    this.firebaseservice.updateUserSandbox(id, evt.target.checked);
  }

  async onChangestatusFilter(evt) {
    this.userlistArray = this.userListArrayCopy.map((x) => x);
    this.p = 1;
    if (evt === this.statusFilterArray[0]) {
      // live users
      this.userlistArray = await this.userListArrayCopy.filter((x) => {
        if (!x.sandbox) {
          return x;
        }
      });
    } else if (evt === this.statusFilterArray[1]) {
      // test users
      this.userlistArray = await this.userListArrayCopy.filter((x) => {
        if (x.sandbox) {
          return x;
        }
      });
    }
    this.sortByDate(!this.dateOrderDesc);
    this.dateOrderDesc = !this.dateOrderDesc;
  }
}
