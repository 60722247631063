<div class="content-wrapper">
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card mrr-card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 d-flex justify-content-between">
              <div class="card--title-row">
                <h4 class="card-title">Affiliates</h4>
                <span class="totalnmbr">Total {{ newTotal }}</span>
                <div class="filter--user text-left mr-2"></div>
              </div>
              <div></div>
            </div>
          </div>
          <div class="table-responsive pt-3">
            <table class="table user-data-table">
              <thead>
                <tr>
                  <th
                    style="cursor: pointer"
                    (click)="sortByDate(dateOrderDesc)"
                  >
                    Created At<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          dateOrderDesc === false &&
                          sortvalue[0] === 'createdAt',
                        'fa-arrow-up':
                          dateOrderDesc === true && sortvalue[0] === 'createdAt'
                      }"
                    ></i>
                  </th>
                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('')"n
                  >
                    Name
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'pardnaName',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'pardnaName'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('amount')">
                    Approved
                  </th>
                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('paymentStatus')"
                  >
                    Application
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-template
                  *ngIf="affiliates?.length != 0"
                  ngFor
                  let-affiliate
                  [ngForOf]="
                    affiliates
                      | SortingFilter : sortvalue
                      | paginate
                        : {
                            itemsPerPage: 50,
                            currentPage: p,
                            totalItems: newTotal
                          }
                  "
                  let-i="index"
                >
                  <tr>
                    <td>
                      {{ changeDateFormat(affiliate?.date) || "" }}
                    </td>
                    <td>
                      <a
                        class="user-af--name"
                        routerLink="/admin/user/{{ affiliate?.userId }}"
                        target="_blank"
                      >
                      {{ affiliate?.name ? affiliate?.name : affiliate?.email}}
                    </a>

                    </td>
                    <td>
                      {{ affiliate?.approved ? "Yes" : "No" }}
                    </td>
                    <td>
                      <button
                        class="btn btn-outline-brand"
                        (click)="openModal(affiliate.id)"
                      >
                        view
                      </button>
                    </td>

                    <td>
                      <button
                        class="btn btn-outline-brand"
                        (click)="approve(affiliate.id, affiliate.approved,affiliate.userId)"
                      >
                        {{ affiliate?.approved ? "disapprove" : "Approve" }}
                      </button>
                    </td>
                  </tr>
                </ng-template>
                <tr *ngIf="newTotal == 0">
                  <td colspan="6" class="text-center">No documents found !</td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="newTotal > 0" class="pagination mt-4 float-right">
              <pagination-controls
                (click)="clickPagination()"
                (pageChange)="getPage($event)"
                directionLinks="true"
                previousLabel="Previous"
                nextLabel="Next"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
